

import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonCol, IonRow, IonSpinner, IonButton, IonIcon,
        IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonCardSubtitle, } from '@ionic/vue';

import LogoImg from '@/components/LogoImg.vue';

// icons
import { logIn, checkmarkCircle } from 'ionicons/icons';

// services
import { useI18n } from 'vue-i18n';

export default {
  name: 'ThankYouPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
                IonGrid, IonCol, IonRow, IonSpinner, IonButton, IonIcon,
                IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonCardSubtitle,
                LogoImg, },
  setup() {
    const { t } = useI18n();

    return {
      t,

      // icons
      logIn, checkmarkCircle,
    }
  },
}
